import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import '../tools/css/Sidebar.css';
import LogoIDEAS from '../tools/Imagenes/IDEAS_nueva.png'
import LogoBici from '../tools/Imagenes/Logobici.png'

import $ from 'jquery'; // <-to import jquery

var Nombre_usuario =sessionStorage.getItem('nombre_usua');
var id_user =sessionStorage.getItem('id_user');
var tipo_id_user =sessionStorage.getItem('tipo_id_user');
var num_id_user =sessionStorage.getItem('num_id_user');
var Avatar =sessionStorage.getItem('avatar');

const ApiURL="https://bisinuapi-dev.azurewebsites.net";

class Sidebar_user extends Component{

  

  Buscar_usuario=async()=>{  


      await axios.get(ApiURL+"/api/Users/GetUser/"+tipo_id_user+"/"+num_id_user)  
      .then(response=>{
        console.log(response.data.data)

        var tipo_user=response.data.data.rol.description
       
        if(tipo_user=="ANFITRION"){

          window.location.href="/home_anfitrion";  

        }else{

          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: 'No tienes permiso para ingresar con este perfil!!!',
            showConfirmButton: true,
            timer: 10000
          })

        } 

      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          
          this.setState({
            form:{   
              num_id_user:this.state.form.num_id_user,  
              nom_user: "",      
              obser_dev:"" 
            }
          })
          this.setState({Prestamos:[]})

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

  
  }

  handleModal_info()
  { 
   
    $('#myModal_info').modal('show');
  }

  render(){
    return(        

      <div>

          <aside className="main-sidebar sidebar-primary elevation-4" id="sidebar">
    
              <a href="/home_user" className="brand-link">
                
                <span className="brand-text">APP CLEM</span>
              </a>
            
              <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                  {/*<div className="image">
                    <img src={Avatar} className="img-circle elevation-2" alt="User Image" />
                  </div>*/}
                  <div className="info">
                    <a id="texto_nameuser" className="d-block">{Nombre_usuario.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</a>
                  </div>
                </div>
              
                {/* Sidebar Menu */}
                <nav className="mt-2">
                  <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    {/* Add icons to the links using the .nav-icon class
                    with font-awesome or any other icon font library */}
                  
                    
                  
                    <li className="nav-item">
                      <a href="/home_user" className="nav-link">
                        <i className="nav-icon fas fa-home" />
                        <p>
                          Inicio                        
                        </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="mi_cuenta_user" className="nav-link">
                        <i className="nav-icon fas fa-user" />
                        <p>
                          Mi cuenta
                        </p>
                      </a>
                    </li>
                    {/*<li className="nav-item">
                      <a href="/prestamos_user" className="nav-link">
                        <i className="nav-icon fas fa-list-ul" />
                        <p>
                          Mis prestamos
                        </p>
                      </a>
                    </li>*/}
                    
                    <li className="nav-header">UTILITARIOS</li>
                    {/* <li className="nav-item">
                      <a href="/puntos_BISINU" className="nav-link">
                        <i className="nav-icon fas fa-map-marked-alt" />
                        <p>Puntos BISINÚ</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="https://adminlte.io/docs/3.1/" className="nav-link">
                        <i className="nav-icon fas fa-photo-video" />
                        <p>Publicidad</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="https://adminlte.io/docs/3.1/" className="nav-link">
                        <i className="nav-icon fas fa-tools" />
                        <p>Taller</p>
                      </a>
                    </li> 
                    <li className="nav-item">
                      <a href="#" onClick={()=> this.Buscar_usuario()} className="nav-link active">
                        <i className="nav-icon fas fa-user-tie" />
                        <p>Soy anfitrión</p>
                      </a>
                    </li>*/}

                    <li className="nav-item">
                      <a href="#" onClick={()=>{this.handleModal_info()}} className="nav-link">
                        <i className="nav-icon fas fa-info-circle" />
                        <p>Acerca de</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/" onClick={()=> sessionStorage.clear()} className="nav-link">
                        <i className="nav-icon fas fa-power-off" />
                        <p>Salir</p>
                      </a>
                    </li>
                    <hr></hr>
                    <p id="texto_final">
                      <div className="row d-flex justify-content-center">
            
                        <p className="col-12 text-center" >
                          <label>By <a  target="_blank"><img  src={LogoIDEAS} alt="IDEAS" width="50%" className="img-fluid img-rounded" /></a> </label>  
                          <br></br>
                          Versión 1.0.0
                        </p>
                      
                      </div>
                    </p>
                  

                  
                  </ul>
                </nav>
                {/* /.sidebar-menu */}
              </div>
              {/* /.sidebar */}
          </aside>

          <div className="modal fade" id="myModal_info" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title text-center" id="staticBackdropLabel">Información de la App</h6>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.handleModalrespuestacitadrop()}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-6">
                              <div className="form-group text-blak">
                                <h2>App CLEM</h2>
                                <p>Versión: 1.0.0</p>
                                
                                <b> Contactanos</b> <br></br>
                                <b> Correo:</b> info@appideas.com.co <br></br>
                                <b> Celular:</b> 3012338691
                              </div>                            
                            </div>
                            <div className="col-md-6">

                              <div className="form-group">
                                <img src={LogoIDEAS} width="100%" />
                              </div>                                
                                                          
                            </div>
                        </div>
                        <hr></hr>
                        <p className="text-blak text-center"> Copyright © 2024 - Ingeniería, Desarrollo y Asesorias  S.A.S. <br />
                        Todos los derechos reservados.</p>                     
                    </div>
                   
                  </div>
                </div>
            </div>
             

        </div>

    
    )
  }
}

export default Sidebar_user;  