// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAEGKw1dfzxgdtw5SC_sDXzeY4rUHwi6jA",
  authDomain: "ideas-jsp.firebaseapp.com",
  projectId: "ideas-jsp",
  storageBucket: "ideas-jsp.appspot.com",
  messagingSenderId: "980721450307",
  appId: "1:980721450307:web:edd27590a63646d8a103f6",
  measurementId: "G-PKWZJPGXD1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app)