import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import '../tools/css/Home_user.css';

import LogoBici from '../tools/Imagenes/BISINU.png'
import Logoestaciones from '../tools/Imagenes/Iconomapa.png'
import LogoBisinu from '../tools/Imagenes/LogoA.png'
import Buscar from '../components/Buscar';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import AwesomeSlider from 'react-awesome-slider';
import Estilo from 'react-awesome-slider/dist/styles.css';

import * as ReactBootStrap from 'react-bootstrap';
import Map from "../components/Map";
import $ from 'jquery'; // <-to import jquery

const Token =sessionStorage.getItem('token');
var id_user =sessionStorage.getItem('id_user');

const ApiURL="https://bisinuapi-dev.azurewebsites.net";

const mapURL = 'AIzaSyAn9QT5SOYMydKuEy6oPDtTzRWDbD0ajjs'

var id_estacion=0;
var id_prestamo=0
var id_bicicleta=0
var buscar_solicitud=''
var btn_estado_prestamos=""


const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 8.7715339,
  lng: -75.8807398
};

const centers = [
  {
  lat: 8.743841290088483,
  lng: -75.89315296335327
  },
  {
    lat: 8.749379159242933,
    lng: -75.87960238756898
  },
  {
    lat: 8.757374479037056,
    lng: -75.87484951330114
  },
  {
    lat: 8.800722867803866,
    lng: -75.85021616085848
  }
];

const onLoad = marker => {
  console.log('marker: ', marker)
}



class Prestamos_user extends Component {

  constructor()
    {
     super()
      this.state={
        Prestamos:[],
        Estaciones:[] , 
        BicicletasXestacion:[],
        loading: false,
        loading_button: true,
        active_button: true,
       
        form:{   
          num_bicicleta:'',  
          obser:''
          
          }     
      }
    }

    handleChange=async e=>{
     
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
        
         console.log(this.state.form);
         //this.Selectdrop();
  }

  Get_id_estacion = (ie) =>{
    //this.setState({tipoid:id.target.value})
    console.log(ie.target.value)
    id_estacion=(ie.target.value)     
  }




  componentDidMount(){  
    
    $('#datos_soli').hide();     
    $('#google_maps').hide();
    this.Listar_Prestamos();
    this.Cargar_Estaciones();

    if(!sessionStorage.getItem('token')){
      window.location.href="/Login_user";
    }
  }

  Sweetaler_solicitud(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  handleModal_estaciones()
  { 
   
    $('#modal_estaciones').modal('show');
  }


  handleModal_solicitud_prestamo()
  { 
   
    $('#modal_solicitud_prestamo').modal('show');
  }


  Listar_Prestamos(){
      
    //this.empty_page_hide();
    
    this.setState({ loading: this.state.loading = false })
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByUser/"+id_user)
    .then((response) => {
      console.log(response);
      this.setState({Prestamos:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
     

      this.setState({ loading: this.state.loading = true })
    })
    .catch((error) => {
      console.log(error);
    })

  }

  Cargar_Estaciones(){
      
    //this.empty_page_hide();
    
    this.setState({ loading: this.state.loading = false })
  
    axios.get(ApiURL+"/api/Stations/ListAll?numeroPagina=1&texto=")
    .then((response) => {
      console.log(response);
      this.setState({Estaciones:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

      

      this.setState({ loading: this.state.loading = true })
    })
    .catch((error) => {
      console.log(error);
    })

  }

  Seleccionar_prestamo=(prestamo)=>{

    $("#id_estacion").val($('#id_estacion > option:first').val());
   
    id_prestamo=prestamo.id
 
  
    //alert(id_estacion)     
    
    //alert(muni)
    
    this.setState({
      form:{
        num_bicicleta:prestamo.bike.code +"-"+ prestamo.bike.number ,
        obser:''   
      }
    })  
    
    $('#modal_solicitud_prestamo').modal('show');
    //var  dato  = servicio.id;
    //alert(this.state.form.fecha_prestamo);
    //alert(this.state.form.hora_prestamo);
  }


  Cargar_BisicletasXestacion(id_estacion){
      
    //this.empty_page_hide();   
  
  
    axios.get(ApiURL+"/api/Stations/GetStock/"+id_estacion)
    .then((response) => {
      console.log(response);
      this.setState({BicicletasXestacion:response.data.data.bikes})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

    })
    .catch((error) => {
      console.log(error);
    })

  }

  Realizar_devolucion=async()=>{

    if(id_prestamo==0 || id_estacion==0 ){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa los datos solicitados',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/StationTransfers/ReturnBike",

        {
          lendingBikeId: id_prestamo,
          stationId:id_estacion,
          annotation: this.state.form.obser,
         
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: mensaje,
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        //this.componentDidMount();
        $('#modal_solicitud_prestamo').modal('hide');
        this.Listar_Solicitudes();

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }    

  }

  render(){

    const {form} = this.state;

    return(
        
       
        <div className="content-wrapper" id="wrapper">
            
            
              <div className="content-header">
                <div className="container-fluid text-center">
                <img  src={LogoBici} alt="BISINU" height="70" className="brand-image img-rounded" />
                </div>{/* /.container-fluid */}
              </div>
             
              <div className="card">

                
                <hr></hr>

               
                <div className="row justify-content-center">

                   <b>Mis prestamos realizados</b>
                
                  <div className="col-md-10" >                                 
                                
                          
                      <div className="card-body">                     
                       
                        
                            <div className="d-flex justify-content-center">

                               {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />} 

                            </div>


                            {this.state.Prestamos.map(prestamo=>{
                              
                            var estado_prestamos=prestamo.state
                          
                            if(estado_prestamos=="activo"){
                              btn_estado_prestamos=<button type="button" id="imgcard_estacion" className="btn btn-primary btn-sm">En uso</button>
                            }else{

                              if(estado_prestamos=="devuelto"){

                                btn_estado_prestamos=<button type="button" id="imgcard_estacion" className="btn btn-success btn-sm">Devuelto</button>

                              }else{

                                btn_estado_prestamos=<button type="button" id="imgcard_estacion" className="btn btn-primary btn-sm" >-----</button>

                              }
                              
                            }

                            return(      


                            <div className="card">

                                <div className="d-flex justify-content-center">                                               

                                    <div className="col-md-2" align="left">
                                      <img src={LogoBisinu} width="100px" id="imgcard_estacion" className="brand-image img-rounded" alt="..." />

                                    </div>
                                    <div className="col-md-6">


                                        <h6 className="card-text" id="imgcard_estacion"  align="left"><b>{prestamo.station.name}</b>
                                        </h6>
                                        <p className="card-text" id="imgcard_estacion" align="left"> <Moment format="YYYY/MM/DD HH:mm"> 
                                        {prestamo.date} 
                                        </Moment>
                                        </p>
                                        <p className="card-text" id="imgcard_estacion" align="left"> Hace: <Moment fromNow ago locale='es'> 
                                        {prestamo.date} 
                                        </Moment>
                                        </p>


                                    </div>
                                    <div className="col-md-4">                     

                                        <h6 className="card-text" id="imgcard_estacion" align="left"><b> {prestamo.bike.code} - {prestamo.bike.number}</b> </h6>
                                        {btn_estado_prestamos}
                            

                                    </div>

                                </div>  
                            
                            </div>  

                             )
                            }
                            )}

                            <div className="d-flex justify-content-center">                              
                                 {/* <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                              */}
                            </div>
                           
                          
                      </div>                     
                   
                  </div>                 
                 
                </div>
                
              </div>

              <div className="modal fade" id="modal_estaciones" >
                    <div className="modal-dialog  modal-dialog-centered">
                      <div className="modal-content">
                        
        
                        <div className="modal-body"> 

                        {this.state.Estaciones.map(estacion=>{
                          return(      
                                              
                                            
                          <div className="card" onClick={()=>{this.Seleccionar_Estacion(estacion);this.handleModal_solicitud_prestamo()}}>

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>{(estacion.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left">{(estacion.address).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}  </p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     


                                      <p className="card-text" id="stokcard_estacion">{estacion.stock + " Disponibles"}  </p>
                                      
                                                                 
                                  </div>

                              </div>  
                      
                          </div>  

                           )
                          }
                          )}         
                      
                        </div>            
        
                      </div>  
                    </div>
              </div>


              <div className="modal fade" id="modal_solicitud_prestamo" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-center" id="staticBackdropLabel">Solicitud de prestamo - {form.num_bicicleta}</h5>
                      
                    </div>
                    <div className="modal-body">

                      <div className="row" id="datos_soli">

                        <div className="col-md-6">
      
                            <div className="form-group">                    
                            
                              <input type="date" className="form-control"  name="fecha_prestamo" value={form.fecha_prestamo} min={form.fecha_prestamo} onChange={this.handleChange} />               
                            </div>
      
                        </div>
      
                        <div className="col-md-6">
      
                            <div className="form-group">                    
                              <label >Fin:</label>
                              <input type="time" className="form-control" name="hora_prestamo" value={form.hora_prestamo} onChange={this.handleChange} />                    
                            </div>
      
                        </div>
            
                    </div> 

                    <div className="col-md-12">
      
                        <div className="form-group">                    
                            <b id="asterisco">* </b><label >Estación de devolución:</label>
                            <select onChange={this.Get_id_estacion}  className="form-control" id="id_bicicleta" >
                                <option  value="0"> Seleccione...</option> ,
                                {this.state.Estaciones.map(item=>                              
                                <option key={item.id} value={item.id}> {item.name}</option>  
                                )}
                                        
                            </select>
                        </div>
  
                    </div>

                    <div className="col-md-12">

                        <div className="form-group">                    
                                <label >Observación:</label>                           
                                <textarea class="form-control" maxLength="100"  rows="2" id="obser" name="obser" value={form.obser} onChange={this.handleChange}  ></textarea>
                                
                        </div>
                        
                    </div>
                    
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Realizar_devolucion()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Entregar Bisinú </button>
                    </div>
                  </div>
                </div>
              </div>



            
        </div>

        

    
    )

  }
}

export default Prestamos_user;   