import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import '../tools/css/Home_user.css';

import LogoBici from '../tools/Imagenes/BISINU.png'
import Logoestaciones from '../tools/Imagenes/Iconomapa.png'
import LogoBisinu_taller from '../tools/Imagenes/LogoBisinu_taller.png'
import Buscar from '../components/Buscar';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import AwesomeSlider from 'react-awesome-slider';
import Estilo from 'react-awesome-slider/dist/styles.css';

import * as ReactBootStrap from 'react-bootstrap';
import Map from "../components/Map";
import $ from 'jquery'; // <-to import jquery

const Token =sessionStorage.getItem('token');
var id_user =sessionStorage.getItem('id_user');

const ApiURL="https://bisinuapi-dev.azurewebsites.net";

const mapURL = 'AIzaSyAn9QT5SOYMydKuEy6oPDtTzRWDbD0ajjs'

var id_estacion="0"
var id_bicicleta="0"
var id_taller="0"
var buscar_reporte=''


const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 8.7715339,
  lng: -75.8807398
};

const centers = [
  {
  lat: 8.743841290088483,
  lng: -75.89315296335327
  },
  {
    lat: 8.749379159242933,
    lng: -75.87960238756898
  },
  {
    lat: 8.757374479037056,
    lng: -75.87484951330114
  },
  {
    lat: 8.800722867803866,
    lng: -75.85021616085848
  }
];

const onLoad = marker => {
  console.log('marker: ', marker)
}



class Reportar_taller extends Component {

  constructor()
    {
     super()
      this.state={
        Taller:[] , 
        Estaciones:[] , 
        BicicletasXestacion:[],
        Reporte_taller:[],
        loading: false,
        loading_button: true,
        active_button: true,
       
        form:{   
          obser:''          
          }     
      }
    }

    handleChange=async e=>{
     
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
        
         console.log(this.state.form);
         //this.Selectdrop();
  }

  Get_id_estacion = (ie) =>{
    //this.setState({tipoid:id.target.value})
    console.log(ie.target.value)
    id_estacion=(ie.target.value)
    id_bicicleta="0"     

    this.Cargar_BisicletasXestacion(id_estacion)
  }


  Get_id_bicicleta = (ib) =>{
    //this.setState({tipoid:id.target.value})
    console.log(ib.target.value)
    id_bicicleta=(ib.target.value)     
  }


  Get_id_taller = (it) =>{
    //this.setState({tipoid:id.target.value})
    console.log(it.target.value)
    id_taller=(it.target.value)     
  }


  Buscar_reporte_taller = (br) =>{
  
    console.log(br.target.value)
    buscar_reporte=(br.target.value)
    this.searchDatareporte(buscar_reporte)
   
  }



  componentDidMount(){  
   
   this.Cargar_Reporte_tallerXusuario()
  }

  Sweetaler_solicitud(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  handleModal_reporte_taller()
  { 

    $("#id_estacion").val($('#id_estacion > option:first').val());
    $("#id_bicicleta").val($('#id_bicicleta > option:first').val());
    $("#id_taller").val($('#id_taller > option:first').val());

    id_estacion="0"
    id_taller="0"
    id_bicicleta="0"

    this.setState({
      form:{
          obser:''   
      }
    })   

   
    $('#modal_reporte_taller').modal('show');
  }


  Cargar_Estaciones(){
      
    //this.empty_page_hide();

    axios.get(ApiURL+"/api/Stations/ListAll?numeroPagina=1&texto=")
    .then((response) => {
      console.log(response);
      this.setState({Estaciones:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

      
    })
    .catch((error) => {
      console.log(error);
    })

  }


  Cargar_Taller(){
      
    //this.empty_page_hide();

  
    axios.get(ApiURL+"/api/Workshops/ListAll?numeroPagina=1")
    .then((response) => {
      console.log(response);
      this.setState({Taller:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

     
    })
    .catch((error) => {
      console.log(error);
    })

  }

  Cargar_BisicletasXestacion(id_estacion){
      
    //this.empty_page_hide();  
  
  
    axios.get(ApiURL+"/api/Stations/GetStock/"+id_estacion)
    .then((response) => {
      //console.log(response);
      this.setState({BicicletasXestacion:response.data.data.bikes})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

    })
    .catch((error) => {
      console.log(error);
    })

  }

  Cargar_Reporte_tallerXusuario(){
      
    
    this.setState({ loading: this.state.loading = false })
  
    axios.get(ApiURL+"/api/WorkshopTransfers/ListAll?numeroPagina=1")
    .then((response) => {
      console.log(response);

      this.setState({Reporte_taller:response.data.data})

      this.setState({ loading: this.state.loading = true })
           
    })
    .catch((error) => {
      console.log(error);
    })

  }


  searchDatareporte(buscar_reporte) {

    this.setState({ loading: this.state.loading = false })
    
    axios.get(ApiURL+"/api/WorkshopTransfers/ListAll?numeroPagina=1&texto="+buscar_reporte)
  .then((response) => {
    //console.log(response);

    this.setState({Reporte_taller:response.data.data})    
   
    this.setState({ loading: this.state.loading = true })

    })
    .catch((error) => {
      console.log(error);
    })
  
  }


  Reporte_taller=async()=>{

    if(id_estacion=="0" || id_bicicleta=="0" || id_taller=="0"){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa los datos solicitados',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.put(ApiURL+"/api/WorkshopTransfers/InToWorkshop",

        {
          bikeId: id_bicicleta,
          workshopId:id_taller,
          stationId:id_estacion,
          annotation: this.state.form.obser,
         
        })  
      .then(response=>{
        //console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: mensaje,
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        //this.componentDidMount();
        $('#modal_reporte_taller').modal('hide');
        this.Cargar_Reporte_tallerXusuario();

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }    

  }

 
  render(){

    const {form} = this.state;

    return(
        
       
        <div className="content-wrapper" id="wrapper">
            
            
              <div className="content-header">
                <div className="container-fluid text-center">
                <img  src={LogoBici} alt="BISINU" height="70" className="brand-image img-rounded" />
                </div>{/* /.container-fluid */}
              </div>
             
              <div className="card">

                
                <hr></hr>

               
                <div className="row justify-content-center">

                  <div className="col-12">
                    
                      <button type="button" id="btn_registrar" className="btn btn-primary btn-block"  onClick={()=>{this.Cargar_Estaciones();this.Cargar_Taller();this.handleModal_reporte_taller()}} >Reportar a Taller</button>
                    
                  </div>

                  <br/> <br/>
  
                  <b>Mis reportes a taller realizados</b>
                
                  <div className="col-md-10" >                                                   
                               
                          
                      <div className="card-body">                     
                       
                        
                            <div className="d-flex justify-content-center">

                               {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />} 

                            </div>

                            <div className="row">
                                      
                                <div className="col-sm-3">
                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                         <div className="input-group-prepend">
                                           <div className="input-group-text"><Buscar/></div>
                                         </div>
                                         <input type="search" className="form-control" placeholder="Buscar por taller" onChange={this.Buscar_reporte_taller}/>
                                      </div>                                
                                </div>
                                
                            </div>                   
                            
                            {this.state.Reporte_taller.map(reporte=>{
                              
                            return(     
                                                
                                              
                            <div className="card">
  
                                <div className="d-flex justify-content-center">                                               
                                    
                                    <div className="col-md-2" align="left">
                                      <img src={LogoBisinu_taller} width="80px" id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                      
                                    </div>
                                    <div className="col-md-4">
                                     
                                        <h6 className="card-text" id="imgcard_estacion" align="left"><b>ID: {reporte.id}</b>
                                        </h6>
                                        <h6 className="card-text" id="imgcard_estacion" align="left"><Moment format="YYYY/MM/DD HH:mm"> 
                                        {reporte.date} 
                                        </Moment>
                                        </h6>
                                        <p className="card-text" id="textcard_estacion" > {(reporte.user.name1).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}  {(reporte.user.lastName1).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</p>
  
                                                                   
                                    </div>
                                    <div className="col-md-6">                     
  
                                       
                                        <h6 className="card-text" id="imgcard_estacion" align="left"><b>Taller: {(reporte.workshop.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} </b> </h6>
                                        <h6 className="card-text" id="imgcard_estacion"> Estado: {reporte.state}</h6>                                       
                                        <p className="card-text" id="textcard_estacion"> {reporte.annotation}</p>
                                                                   
                                    </div>
                                   
  
                                </div>  
                        
                            </div>  
  
                             )
                            }
                            )}
                            

                            <div className="d-flex justify-content-center">                              
                                 {/* <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                              */}
                            </div>
                           
                          
                      </div>  

                                        
                   
                  </div>                 
                 
                </div>
                
              </div>

             

              <div className="modal fade" id="modal_reporte_taller" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-center" id="staticBackdropLabel">Reportar a taller</h5>
                      
                    </div>
                    <div className="modal-body">

                    <div className="col-md-12">
      
                        <div className="form-group">                    
                            <b id="asterisco">* </b><label >Estación:</label>
                            <select onChange={this.Get_id_estacion}  className="form-control" id="id_estacion" >
                                <option  value="0"> Seleccione...</option> ,
                                {this.state.Estaciones.map(item=>                              
                                <option key={item.id} value={item.id}> {(item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                )}
                                        
                            </select>
                        </div>
  
                    </div>

                    <div className="col-md-12">
      
                        <div className="form-group">                    
                            <b id="asterisco">* </b><label >Bicicleta:</label>
                            <select onChange={this.Get_id_bicicleta}  className="form-control" id="id_bicicleta" >
                                    <option  value="0"> Seleccione...</option> ,
                                    {this.state.BicicletasXestacion.map(item=>                              
                                    <option key={item.id} value={item.id}> {item.code}-{item.number}</option>  
                                    )}
                                            
                                </select>
                        </div>
  
                    </div>

                    <div className="col-md-12">
      
                        <div className="form-group">                    
                            <b id="asterisco">* </b><label >Taller:</label>
                            <select onChange={this.Get_id_taller}  className="form-control" id="id_taller" >
                                    <option  value="0"> Seleccione...</option> ,
                                    {this.state.Taller.map(item=>                              
                                    <option key={item.id} value={item.id}> {(item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                    )}
                                            
                                </select>
                        </div>
  
                    </div>

                    <div className="col-md-12">

                        <div className="form-group">                    
                                <label >Observación:</label>                           
                                <textarea class="form-control" maxLength="30"  rows="2" id="obser" name="obser" value={form.obser} onChange={this.handleChange}  ></textarea>
                                
                        </div>
                        
                    </div>
                    
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Reporte_taller()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Reportar </button>
                    </div>
                  </div>
                </div>
              </div>



            
        </div>

        

    
    )

  }
}

export default Reportar_taller;   