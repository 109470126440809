import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import '../tools/css/Home_user.css';

import LogoBici from '../tools/Imagenes/BISINU.png'
import Logoestaciones from '../tools/Imagenes/Iconomapa.png'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import AwesomeSlider from 'react-awesome-slider';
import Estilo from 'react-awesome-slider/dist/styles.css';

import * as ReactBootStrap from 'react-bootstrap';
import Map from "../components/Map";
import $ from 'jquery'; // <-to import jquery

const Token =sessionStorage.getItem('token');

const ApiURL="https://bisinuapi-dev.azurewebsites.net";

const mapURL = 'AIzaSyAn9QT5SOYMydKuEy6oPDtTzRWDbD0ajjs'


const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 8.7715339,
  lng: -75.8807398
};

const centers = [
  {
  lat: 8.743841290088483,
  lng: -75.89315296335327
  },
  {
    lat: 8.749379159242933,
    lng: -75.87960238756898
  },
  {
    lat: 8.757374479037056,
    lng: -75.87484951330114
  },
  {
    lat: 8.800722867803866,
    lng: -75.85021616085848
  }
];

const onLoad = marker => {
  console.log('marker: ', marker)
}



class Mapa extends Component {

  constructor()
    {
     super()
      this.state={
        Estaciones:[] , 
        loading: false,
        loading_button: true,
        active_button: true,
       
        form:{   
          nom_estacion:'',  
          dir_estacion:''
          
          }     
      }
    }

  componentDidMount(){    
    
    $('#google_maps').hide();
    this.Cargar_Estaciones();

    if(!sessionStorage.getItem('token')){
      window.location.href="/Login_user";
    }
  }

  Cargar_Estaciones(){
      
    //this.empty_page_hide();
    
    this.setState({ loading: this.state.loading = false })
  
    axios.get(ApiURL+"/api/Stations/ListAll?numeroPagina=1&texto=")
    .then((response) => {
      console.log(response);
      this.setState({Estaciones:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

      if(this.state.Estaciones.length==0){
        $('#google_maps').hide();   
       
      }else{
        $('#google_maps').show();     
       
      }

      this.setState({ loading: this.state.loading = true })
    })
    .catch((error) => {
      console.log(error);
    })

  }

  render(){

    return(
        
       
        <div className="content-wrapper" id="wrapper">
            
            
              <div className="content-header">
                <div className="container-fluid text-center">
               
                </div>{/* /.container-fluid */}
              </div>
             
              <div className="card">

               

                <div className="d-flex justify-content-center">
          
                      {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
              
                </div>

                <div className="row justify-content-center">

                   
                
                  <div className="col-md-10" >                    
                  
                  <LoadScript
                      googleMapsApiKey={mapURL}
                  >
                  <GoogleMap
                    id="marker-example"
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={13}
                  >
                  { /* Child components, such as markers, info windows, etc. */ }
                  <>    </>

                  {this.state.Estaciones.map(estacion=>{ return(  
                  
                    <Marker
                      icon={Logoestaciones}
                      position={{
                        lat: parseFloat(estacion.latitude),
                        lng: parseFloat(estacion.longitude)
                        }}
                      options={{
                        label:{
                          text:estacion.name,
                          className:"map-marker"
                        }
                       }}
                    />                                                                    
          
                  )})}

                  </GoogleMap>
                  </LoadScript>
                      
                   
                  </div>                 
                 
                </div>




              </div>
                
            
        </div>

    
    )

  }
}

export default Mapa;   