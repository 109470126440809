import React, {Component} from "react";

import $ from 'jquery'; // <-to import jquery

import 'bootstrap';


class Pagina404 extends Component {

    constructor()
    {
     super()
      this.state={
        show:false,
        Sedes:[] , 
        Departamentos: [],
        Municipios:[],
        loading: false,
        loading_button: true,
        active_button: true,
        form:{   
          nom_sede:'',  
          cod_habilita:'',   
          dir_sede:'',
          tel_sede:'',
          idsede_edit:'',     
          nom_sedeedit:'',  
          cod_habilitaedit:'',   
          dir_sedeedit:'',
          tel_sedeedit:'',      
          }     
      }
    }

    handleChange=async e=>{
     
        await this.setState({
           form:{
                ...this.state.form,
                [e.target.name]: e.target.value
                }
           })
          
           console.log(this.state.form);
           //this.Selectdrop();
    }

    componentDidMount(){

     
       
    }


//<- muestra modal para crear sede
handleModalnewpunto()
{ 
  $('#modal-newpunto').modal('show');
}


    render(){  

        const {form} = this.state;

    return(

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>404 Error de página</h1>
                </div>
               
              </div>
            </div>{/* /.container-fluid */}
          </section>
          {/* Main content */}
          <section className="content">
            <div className="error-page">
              <h2 className="headline text-blue"> 404</h2>
              <div className="error-content">
                <h3 className="text-blue"><i className="fas fa-exclamation-triangle text-yellow" /> Lo siento! La página no exixte.</h3>
                <p>
                  Si deseas seguir navegando en nuestro portal y disfrutar de la mejor experiencia de la ciudad haciendo uso de BISINÚ.
                  ingresa a nuestra <a id="copyright" className="text-blue" href="https://bisinu.com.co/">Página web</a> Descarga la APP y registrate.
                </p>
                
              </div>
              {/* /.error-content */}
            </div>
            {/* /.error-page */}
          </section>
          {/* /.content */}

          <div>
            <footer className="text-center" id="footer_404">
              <strong>Copyright © 2022 <a id="copyright" href="">IDEAS</a> - </strong> Todos los derechos reservados.
             
            </footer>

          </div>

        </div>


    )

    }

}


export default Pagina404;       