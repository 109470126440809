import React, {Component,useState} from 'react';

//Componente para boton editar

class Trasladar extends Component {   
      

      render(){ 
          
     
       return (   
       
          <div >

              <button className="btn btn-edit">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#192A70" class="bi bi-repeat" viewBox="0 0 16 16">
                  <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z"/>
                </svg>
              </button>                    
           
          </div>    
        
      )
  
    }
    
  }  
  
  
  export default Trasladar;