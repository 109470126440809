import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import '../tools/css/Home_user.css';

import LogoCLEM from '../tools/Imagenes/Logotipo-Clinica.png'
import Logoestaciones from '../tools/Imagenes/Iconomapa.png'
import LogoBisinu_taller from '../tools/Imagenes/LogoBisinu_taller.png'
import Buscar from '../components/Buscar';
import Icono_img from "../components/Icono_img";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import AwesomeSlider from 'react-awesome-slider';
import Estilo from 'react-awesome-slider/dist/styles.css';

import * as ReactBootStrap from 'react-bootstrap';
import Map from "../components/Map";
import $ from 'jquery'; // <-to import jquery

const Token =sessionStorage.getItem('token');
var tipo_id_user =sessionStorage.getItem('tipo_id_user');
var num_id_user =sessionStorage.getItem('num_id_user');

const ApiURL="https://bisinuapi-dev.azurewebsites.net";

const mapURL = 'AIzaSyAn9QT5SOYMydKuEy6oPDtTzRWDbD0ajjs'

var id_estacion="0"
var id_bicicleta="0"
var id_taller="0"
var buscar_reporte=''
var avatar=[];
var file_base64="";


class Mi_cuenta extends Component {

  constructor()
    {
     super()
      this.state={
        Data_user:[] , 
        loading: false,
        loading_button: true,
        active_button: true,
       
        form:{   
          tipo_id:'',
          num_id:'',  
          nom_user1:'',
          nom_user2:'',
          apell_user1:'',
          apell_user2:'',         
          genero_user:'',
          fecha_naci:'',
          tel_user:'',
          dir_user:''
          }     
      }
    }

    handleChange=async e=>{
     
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
        
         console.log(this.state.form);
         //this.Selectdrop();
  }

  Get_id_estacion = (ie) =>{
    //this.setState({tipoid:id.target.value})
    console.log(ie.target.value)
    id_estacion=(ie.target.value)
    id_bicicleta="0"     

    this.Cargar_BisicletasXestacion(id_estacion)
  }


  Get_id_bicicleta = (ib) =>{
    //this.setState({tipoid:id.target.value})
    console.log(ib.target.value)
    id_bicicleta=(ib.target.value)     
  }

  GetImg = (i) =>{      
  
    avatar=i.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(avatar);
    reader.onloadend =  () => {
      
      file_base64 = (reader.result).split(',')[1];

    
      //alert(base64data)
      //console.log(file_base64)   
    } 

    this.Sweetaler_solicitud();
    //files_view=URL.createObjectURL(i.target.files[0]);
    
    //$('#modal_documento').modal('show');
    //alert(avatar)
  }


  handleModal_reenviarsms()
  { 
    $("#cel_user_reeviar").val(""); 
    $('#modal_reenviarsms').modal('show');
  }


  componentDidMount(){  
  
   this.Cargar_informacion_usuario()
  
  }
  

  Sweetaler_solicitud(){
    Swal.fire({
      title: 'Desea cambiar la foto de perfil?',
      //text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Subir imagen'
    }).then((result) => {
      if (result.isConfirmed) {
       this.Cambiar_foro_perfil();
      }
    })
  }




  Cargar_informacion_usuario=async()=>{
      
    this.setState({ loading: this.state.loading = false })

    await axios.get(ApiURL+"/api/Users/GetUser/"+tipo_id_user+"/"+num_id_user)
    .then((response) => {
      console.log(response);
      this.setState({Data_user:response.data.data.user})

     
      this.setState({ loading: this.state.loading = true })

      
    })
    .catch((error) => {
      this.setState({ loading: this.state.loading = true })
      console.log(error);
    })

  }


  Reporte_taller=async()=>{

    if(id_estacion=="0" || id_bicicleta=="0" || id_taller=="0"){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa los datos solicitados',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.put(ApiURL+"/api/WorkshopTransfers/InToWorkshop",

        {
          bikeId: id_bicicleta,
          workshopId:id_taller,
          stationId:id_estacion,
          annotation: this.state.form.obser,
         
        })  
      .then(response=>{
        //console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: mensaje,
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        //this.componentDidMount();
        $('#modal_reporte_taller').modal('hide');
        this.Cargar_Reporte_tallerXusuario();

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }    

  }


  Cambiar_foro_perfil=async()=>{

    if(file_base64=="" ){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Seleccione una imagen',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/Users/UploadAvatar",

        {
          strBase64: file_base64,
          extension:"png",
        
         
        })  
      .then(response=>{
        //console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: 'Su foto fué actualizada con éxito.',
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        //this.componentDidMount();
       
        this.Cargar_informacion_usuario();

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }    

  }

 
  render(){

    const {form} = this.state;

    return(
        
       
        <div className="content-wrapper" id="wrapper">
            
            
              <div className="content-header">
                <div className="container-fluid text-center">
                  <img  src={LogoCLEM} alt="Logo CLEM" height="100" className="brand-image img-rounded" />
                  <br></br><br></br>
                  <b>Información de mi cuenta</b>
                </div>{/* /.container-fluid */}
              </div>
             
              <div className="card">

              
               
                <div className="row justify-content-center">
               
                 
                
                  <div className="col-md-10"  > 

                      <div className="d-flex justify-content-center">

                          {this.state.loading?false: <ReactBootStrap.Spinner animation="grow" style={{ width: "4rem", height: "4rem" }} />}   
                      
                      </div>                          
                          
                      <div className="card-body" >

                            <div className="row justify-content-center">  


                                <div className="profile-img">
                                  <img src={this.state.Data_user.avatar}  width="70px" />
                                  <div className="file btn btn-lg btn-primary">
                                    Cambiar foto
                                    <input type="file" name="file"  accept="image/jpg,image/jpeg,image/bmp,image/png" onChange={this.GetImg} />
                                  </div>
                                </div>
                      

                            </div>

                            <br/>

                            <div className="row">                        
                                
                                <div className="col-md-6">
    
                                    <div className="form-group">                    
                                        <label >Tipo de identificación</label>
                                        <input type="text" className="form-control"  id="tipo_id" name="tipo_id" value={this.state.Data_user.identificationType} readOnly />
                                    </div>
                                
                                </div>
                                
                                <div className="col-md-6">
                                
                                    <div className="form-group">                    
                                        <label >Número de identificación</label>
                                        <input type="text" maxLength="12" className="form-control" autoComplete='nope' id="num_id" name="num_id" value={this.state.Data_user.identificationNumber} onChange={this.handleChange} readOnly />                       
                                    </div>
                                
                                </div>
                               
                            </div> 

                            <div className="row">                    
                               
                                <div className="col-md-6">
                      
                                    <div className="form-group">                    
                                        <label >Primer nombre</label>
                                        <input type="text" className="form-control"  id="nom_user" name="nom_user" value={this.state.Data_user.name1 ? this.state.Data_user.name1.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} readOnly />                       
                                    </div>
                                
                                </div>

                                <div className="col-md-6">
                      
                                    <div className="form-group">                    
                                        <label >Segunto nombre</label>
                                        <input type="text" className="form-control"  id="nom_user" name="nom_user" value={this.state.Data_user.name2 ? this.state.Data_user.name2.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} readOnly />                       
                                    </div>
                                
                                </div>

                            </div> 

                            <div className="row">                    
                               
                                <div className="col-md-6">
                      
                                    <div className="form-group">                    
                                        <label >Primer apellido</label>
                                        <input type="text" className="form-control"  id="nom_user" name="nom_user" value={this.state.Data_user.lastName1 ? this.state.Data_user.lastName1.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} readOnly />                       
                                    </div>
                                
                                </div>

                                <div className="col-md-6">
                      
                                    <div className="form-group">                    
                                        <label >Segunto apellido</label>
                                        <input type="text" className="form-control"  id="nom_user" name="nom_user" value={this.state.Data_user.lastName2 ? this.state.Data_user.lastName2.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} readOnly />                       
                                    </div>
                                
                                </div>

                            </div>  

                            <div className="row">                    
                               
                                <div className="col-md-6">
                      
                                    <div className="form-group">                    
                                        <label >Sexo</label>
                                        <input type="text" className="form-control"  id="nom_user" name="nom_user" value={this.state.Data_user.gender} readOnly />                       
                                    </div>
                                
                                </div>

                                <div className="col-md-6">
                      
                                    <div className="form-group">                    
                                        <label >Fecha de nacimiento</label>
                                        <input type="text" className="form-control"  id="nom_user" name="nom_user" value={moment(this.state.Data_user.birthday).format("DD/MM/YYYY")} readOnly />                       
                                    </div>
                                
                                </div>

                            </div>  

                            <div className="row">                    
                               
                                <div className="col-md-6">
                      
                                    <div className="form-group">                    
                                        <label >Celular</label>
                                        <input type="text" className="form-control"  id="nom_user" name="nom_user" value={this.state.Data_user.phone} readOnly />                       
                                    </div>
                                
                                </div>

                                <div className="col-md-6">
                      
                                    <div className="form-group">                    
                                        <label >Dirección</label>
                                        <input type="text" className="form-control"  id="nom_user" name="nom_user" value={this.state.Data_user.address} readOnly />                       
                                    </div>
                                
                                </div>

                            </div>  
                            
                            <div className="row">
                        
                              <div className="col-12">
                                <button type="button" id="btn_eliminar_cuenta" className="btn btn-danger btn-block" disabled={!this.state.active_button} onClick={()=> this.handleModal_reenviarsms()} >  Eliminar cuenta </button>
                              </div>
                             
                            </div>  
                          
                      </div>  

                                        
                   
                  </div>                 
                 
                </div>
                
              </div>


              <div className="modal fade" id="modal_reenviarsms">
                 <div className="modal-dialog  modal-dialog-centered">
                   <div className="modal-content">
                     <div className="modal-header">
                       <h4 className="modal-title"><b>Importante</b></h4>
                       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">×</span>
                       </button>
                     </div>

                     <div className="modal-body">
                         
                        <p className="text-center" id="text-registrarte"> Para realizar la solicitud de eliminació de tu cuenta sigue los siguientes pasos <br></br>
                          1- Escribir una PQRS en nuestra pagina oficinal <a href="https://bisinu.com.co/pqr/" target="_blank"> https://bisinu.com.co/pqr/</a>  <br></br>
                          2-Describir la petición o solicitud e indicar los datos allí solicitados (datos del usuario que solicita la eliminación de la cuenta) <br></br>
                          3- En un tiempo no máximo de 48 horas, el equipo de soporte se comunicará con el usuario para validar preguntas y verificar datos como correo electrónico y numero de celular atreves de códigos de verificación que son enviados a estos <br></br>
                          4- Una vez validada la información la cuenta se elimina de manera inmediata.
                        </p>
                         
                         
                         <div className="row">
                           <div className="col-12">
                           <a href="https://bisinu.com.co/pqr/" target="_blank" type="button" id="btn_registrar" className="btn btn-primary btn-block" > Realizar solicitud </a>
                           </div>
                          
                         </div>
                           
                     </div> 

                   </div>  
                 </div>
            </div>


            
        </div>

        

    
    )

  }
}

export default Mi_cuenta;   