import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import * as ReactBootStrap from 'react-bootstrap';
import {  GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import { Autocomplete } from '@react-google-maps/api';
import '../tools/css/Puntos_Bisinu.css';

import Marcador from '../tools/Imagenes/Marcador.svg'
import Editar from '../components/Editar';
import Trasladar from '../components/Trasladar';

import $ from 'jquery'; // <-to import jquery

import 'bootstrap';

const ApiURL="https://bisinuapi-dev.azurewebsites.net";
const mapURL = 'AIzaSyAn9QT5SOYMydKuEy6oPDtTzRWDbD0ajjs'

var Latitud=8.7715339;
var Longitud=-75.8807398;
var Latitud_taller=8.7715339;
var Longitud_taller=-75.8807398;
var tipo_id="0";
var id_user=0
var id_taller="0"

const containerStyle = {
  width: '100%',
  height: '450px'
};

const center = {
  lat: 8.7715339,
  lng: -75.8807398
};

const onLoad = marker => {
  Latitud= marker.position.lat()
  Longitud= marker.position.lng()
  Latitud_taller= marker.position.lat()
  Longitud_taller= marker.position.lng()
  //alert(Latitud+ " "+" " +Longitud)
  
}


class Usuario_taller extends Component {

    constructor()
    {
     super()
      this.state={
        Tipo_id:[],     
        Taller:[],
        loading: false,
        loading_button: true,
        active_button: true,
       
        form:{   
          num_id_user:'', 
          nom_user:'', 
          }     
      }
    }

    handleChange=async e=>{
     
        await this.setState({
           form:{
                ...this.state.form,
                [e.target.name]: e.target.value
                }
           })
          
           console.log(this.state.form);
           //this.Selectdrop();
    }

    _handleKeyDown = (e) => {
     
      if (e.key === 'Enter' || e.key=='Tab') {
       
        this.Buscar_usuario()
        
      }
    }
    

    componentDidMount(){

      $('[data-toggle="tooltip"]').tooltip()

      $('#btn_edita_estacion').hide();   
      $('#btn_edita_bicicleta').hide();
      $('#btn_edita_taller').hide();        
       this.Cargar_tipo_id();
    }

    onMarkerDragEnd = (e) => {   
       
      Latitud=e.lat()
      Longitud=e.lng()
      //alert(Latitud+ " "+" " +Longitud)
      //console.log(e.lat());
      //console.log(e.lng());
         
    };

    onMarkerDragEnd_taller = (e) => {   
       
      Latitud_taller=e.lat()
      Longitud_taller=e.lng()
      //alert(Latitud+ " "+" " +Longitud)
      //console.log(e.lat());
      //console.log(e.lng());
         
    };

    Limpiar_campos(){
      this.setState({
        form:{   
          num_id_user:'',          
          nom_user:''
        }
      }) 
    }
  

    handleModalnewbicicleta()
    {   
      $("#id_tipoid").val($('#id_tipoid > option:first').val());   
      tipo_id="0"  
      id_user=0 
      $("#id_taller").val($('#id_taller > option:first').val());   
      id_taller="0" 
      $('#modal_asociar_usuario').modal('show');
    }

    GettipoID = (id) =>{
      //this.setState({tipoid:id.target.value})
      console.log(id.target.value)
      tipo_id=(id.target.value)     
    }

    Get_id_taller = (it) =>{
      //this.setState({tipoid:id.target.value})
      console.log(it.target.value)
      id_taller=(it.target.value)     
    }


    Cargar_tipo_id=async()=>{    
    
      await axios.get(ApiURL+"/api/IdentificationTypes/ListAll")
        .then((response) => {
          //console.log(response);
          this.setState({Tipo_id: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
    }

    Cargar_Taller(){
      
      //this.empty_page_hide();
  
    
      axios.get(ApiURL+"/api/Workshops/ListAll?numeroPagina=1")
      .then((response) => {
        console.log(response);
        this.setState({Taller:response.data.data})
        //this.setState({Pager_Convenios: response.data.pager.items})
        //this.setState({pager_infoconvenio: response.data.pager.showData})
        //this.Selectdrop()
  
       
      })
      .catch((error) => {
        console.log(error);
      })
  
    }

    Buscar_usuario=async()=>{

      if(tipo_id=="0" || this.state.form.num_id_user.length==0 || this.state.form.num_id_user==''){
  
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })
  
      }else{
  
      
  
        await axios.get(ApiURL+"/api/Users/GetUser/"+tipo_id+"/"+this.state.form.num_id_user)  
        .then(response=>{
          //console.log(response.data.data)
          var  mensaje  = response.data.message;
          id_user=response.data.data.id

          this.setState({
            form:{   
              num_id_user:this.state.form.num_id_user,          
              nom_user:response.data.data.name1.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +" "+ response.data.data.lastName1.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) 
            }
          }) 
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: '¡Muy Bien!',
            html: 'Usuario encontrado con éxito',
            showConfirmButton: true,
            timer: 20000
          })
  
       
  
          //this.componentDidMount();
          
  
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 20000
            })
  
          
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
  
           
  
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
  
           
        }
        //console.log(error.config);
      
      })
  
      }    
  
    }

    Asociar_Usuario=async()=>{

      if( id_user==0 || id_taller=="0"){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/Users/AssignWorkshop",
  
          {
            workshopId: id_taller,
            userId: id_user,
           
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: '¡Muy Bien!',
            html: mensaje,
            showConfirmButton: true,
            timer: 20000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

        
          $('#modal_asociar_usuario').modal('hide');
        
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })

      }      
  
    }

     Cargar_Usuario_Taller(){
      
      //this.empty_page_hide();
      
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Workshops/ListAll?numeroPagina=1")
      .then((response) => {
        console.log(response);
        this.setState({Taller:response.data.data})
        //this.setState({Pager_Convenios: response.data.pager.items})
        //this.setState({pager_infoconvenio: response.data.pager.showData})
        //this.Selectdrop()
  
        if(this.state.Taller.length==0){
          //$('#google_maps').hide();   
         
        }else{
          //$('#google_maps').show();     
         
        }
  
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
  
    }


    render(){  

        const {form} = this.state;

    return(

    <div className="content-wrapper">

        <div className="col-12 col-sm-12">
      
                <div className="row ">
          
                    <div className="col-sm-12">
          
                         <br></br>
                         <div className="text-center" id="title"> <h5> <b> Usuario Taller </b>  </h5> </div>
          
                    </div>      
          
                </div> 
                
                <div className="row justify-content-md-center">
    
            
                    <div  className="col-sm-4 text-center">
                          <button id="btn_crear" className="btn btn-default" onClick={()=>{this.handleModalnewbicicleta();this.Limpiar_campos();this.Cargar_Taller()}}>Asociar usuario
                             <svg width="20" height="15" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                             <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                             </svg> 
                          </button> 
            
                   
                    </div>
          
                </div>              
                            
                    <div className="card-body">            

                         
                        
                                                                          
                                <div class="d-flex justify-content-center">
                                    
                                   {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                            
                                </div>
                                        
                                <div className="card table-responsive" id="tabla2">                                    
                                   
                        
                                        <table className="table table-bordered table-striped table-hover">
                                            <thead>
                                              <tr>
                                                <th>ID</th>
                                                <th>Usuario</th>
                                                <th>Taller</th>
                                                 
                                              
                                              </tr>
                                            </thead>
                                            <tbody>                                              
                                           
                                            
                                      
                                            </tbody>
                                        </table>
                                                                                               
                                                
                                 
                                </div>
                                <div className="d-flex justify-content-center">                              
                                   {/* <img id="empty_page2" src={emptypage} alt="logo"  width= "200"/>                              */}
                                </div>
                          
                            
                    </div>      
        
        </div>
      

       
        <div className="modal fade" id="modal_asociar_usuario" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title"><b>Asociar usuario a taller</b></h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.handleModalrespuestacitadrop()}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
      
                    <div className="row">                               
                                
                          <div className="col-md-6">
                              <div className="form-group">                    
                                  <label >Tipo de identificación</label>
                                  <select onChange={this.GettipoID}  className="form-control" id="id_tipoid" name="id_tipoid"  >
                          
                                      <option  value="0"> Seleccione...</option> ,
                                      {this.state.Tipo_id.map(item=>                              
                                      <option key={item.id} value={item.id}>  {item.description ? (item.description).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  </option>  
                                      )}
                                            
                                  </select>
                              </div>
                          
                          </div>
                          
                          <div className="col-md-6">
                          
                              <div className="form-group">                    
                                  <label >Número de identificación</label>
                                  <input type="text" maxLength="12" className="form-control" autoComplete='nope' data-toggle="tooltip" data-placement="top" title="Presion la tecla Enter o Tab para consultar" placeholder="Identificación" id="num_id_user" name="num_id_user" onChange={this.handleChange} onKeyDown={this._handleKeyDown} value={form.num_id_user} />                       
                              </div>
                          
                          </div>

                         
                      </div> 

                      <div className="row">                               

                          <div className="col-md-6">
                          
                              <div className="form-group">                    
                                  <label >Nombre usuario</label>
                                  <input type="text" className="form-control"  id="nom_user" name="nom_user" value={form.nom_user} readOnly />                       
                              </div>
                          
                          </div>

                          <div className="col-md-6">
                               <div className="form-group">                    
                                    <label >Taller:</label>
                                    <select onChange={this.Get_id_taller}  className="form-control" id="id_taller" >
                                            <option  value="0"> Seleccione...</option> ,
                                            {this.state.Taller.map(item=>                              
                                            <option key={item.id} value={item.id}> {(item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                            )}
                                                    
                                        </select>
                                </div>
                          
                          </div>

                      </div>           
                   
              </div>
      
              <div className="modal-footer justify-content-between">        
                <button type="button" id="btn_crear_bicicleta" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Asociar_Usuario()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Guardar</button>
                
                <button type="button" className="btn btn-danger" data-dismiss="modal" >Cerrar</button>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>      


      
      
    </div>

    )

    }

}


export default Usuario_taller;       