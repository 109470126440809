import React, {Component,useState, useEffect} from "react";

//Firebase
import { getAuth, signInAnonymously } from "firebase/auth";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Login.css';
import '../tools/css/Iframe.css';

import LogoIdeas from '../tools/Imagenes/ideas logo.png'

//Firebase
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../components/Firebase";

//Importar componente de notificacion
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

import $ from 'jquery'; // <-to import jquery



class Notificaciones_firebase extends Component {

  constructor()
    {
     super()
      this.state={
       
        form:{   
          id_token:'' 
          
          }     
      }
    }


  componentDidMount(){

    $('#div_1').hide(); 
    this.loguearse();
    this.activarMensajes();

    onMessage(messaging, message=>{
      console.log("Tu mensaje: ", message);
      toast(message.notification.title)
      //toast(message.notification.body)
      
      
      
    })

  }

  loguearse=async()=>{    
    
    signInAnonymously(getAuth()).then(usuario=>console.log(usuario));

  }
 

  activarMensajes=async()=>{    
    
    const token= await getToken(messaging,{
      vapidKey:
      "BF21zEB6CYegyoEQJF4fRVTjtwoG-ziXwKY5sxY6T_2lBuncdZ-ledMk_2s9pX-zQmffN5sdVCpcvzy1OFeQa8Y"
    }).catch(error => console.log("Tuviste un error al generar el token"));

    if(token) console.log("Tu token: ",token);
    alert(token);
    
    this.setState({
      form:{
        id_token:token
       
      }
    }) 
    if(!token) console.log("No existe token");

  }


  render(){

    const {form} = this.state;

    return(
    <div>
        
        <div>

                        <iframe id="myIframe" scrolling="no"
                              src="https://190.60.244.250:8443/Tablero_Imagenes.jsp">
                        </iframe>
                        

        </div>

        <div id="div_"> <br></br>

                  <ToastContainer/>
                  <button onClick={()=> this.loguearse()}>Logearse</button>
                  <button onClick={()=> this.activarMensajes()}>Generar Token</button>
                                    <div className="form-group">                    
                                  
                                      <div className="input-group mb-3">
                                    
                                        <input type="email" className="form-control" autocomplete="nope" id="id_token" name="id_token" placeholder="id_token" value={this.state.form.id_token}  />
                                        <div className="input-group-append">
                                          <div className="input-group-text">
                                            <span className="fas fa-user" />
                                          </div>
                                        </div>
                                      </div>     
                                    </div>
                                   

        </div>
                          

    </div>

    
  
    )
}
}


export default Notificaciones_firebase;       