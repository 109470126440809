import React from "react";

export default function Footer(){
    return(
       
        <div>
           <footer className="main-footer">
              <strong>Copyright © 2022 <a href="">IDEAS</a> - </strong> Todos los derechos reservados.
              <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.0.1
              </div>
            </footer>

        </div>

    )
}